import i18next from 'i18next';
import { billingType } from 'src/common/enums/enums';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { getOrderCurrencySymbol } from 'src/utils/price';

export function getCashValue(order: OrderToShip): string {
    if (order.billing.type === billingType.QUATRO) {
        return 'Quatro';
    }

    if (order.isPaid) {
        return i18next.t('cartagePrint.paid');
    }

    const currency = getOrderCurrencySymbol(order);
    return `${order.totalPriceWithTax} ${currency}`;
}

export function getCartageTotalCashValue(orders: OrderToShip[]): number {
    const notPaidOrders = orders.filter(
        ({ isPaid, billing }) => !isPaid && billing.type !== billingType.QUATRO
    );
    const cartageTotalCashValue = notPaidOrders.reduce(
        (sum, { totalPriceWithTax }) => sum + totalPriceWithTax,
        0
    );

    return cartageTotalCashValue;
}
