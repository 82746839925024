import get from 'lodash.get';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { country as countryEnum } from '../common/enums/enums';
import { Order } from '../models/Order';

const deprecatedVatCountryMap = {
    [countryEnum.SK]: 20,
    [countryEnum.HU]: 27,
    [countryEnum.CZ]: 21,
    [countryEnum.RO]: 19
};

function getVatDeprecated(country: string): number {
    return deprecatedVatCountryMap[country];
}

export function calculatePriceWithoutTax(
    priceWithTax = 0,
    country: string,
    vatRate: number | undefined
): number {
    const tax = vatRate ?? getVatDeprecated(country);
    const priceWithoutTax = (priceWithTax * 100) / (1 + tax / 100);
    return Math.round(priceWithoutTax) / 100;
}

export enum CurrencySymbol {
    Eur = '€',
    Huf = 'Ft',
    Pln = 'PLN',
    Ck = 'Kč',
    Lei = 'Lei'
}

const countryCurrencyMap = {
    [countryEnum.SK]: CurrencySymbol.Eur,
    [countryEnum.HU]: CurrencySymbol.Huf,
    [countryEnum.CZ]: CurrencySymbol.Ck,
    [countryEnum.RO]: CurrencySymbol.Lei
};

export function getOrderCurrencySymbol(
    order: Order | OrderToShip
): CurrencySymbol {
    const country = get(order, 'shop.country', countryEnum.SK);
    return countryCurrencyMap[country] || CurrencySymbol.Eur;
}

//HU EU vatId pattern HUxxxxxxxx
const huEuVatIdRegexp = /^HU[0-9]{8}$/;
const hasValidHuEuVatId = (vatId: string): boolean =>
    huEuVatIdRegexp.test(vatId);

//HU EU vatId pattern CZ
const czEuVatIdRegexp = /^CZ[0-9]{8,10}$/;
const hasValidCzEuVatId = (vatId: string): boolean =>
    czEuVatIdRegexp.test(vatId);

//HU EU vatId pattern RO
const roEuVatIdRegexp = /^RO[0-9]{2,10}$/;
const hasValidRoEuVatId = (vatId: string): boolean =>
    roEuVatIdRegexp.test(vatId);

export function hasZeroTax(
    shopCountry: string,
    vatId: string | undefined
): boolean {
    if (shopCountry === countryEnum.HU) {
        return hasValidHuEuVatId(vatId);
    }

    if (shopCountry === countryEnum.CZ) {
        return hasValidCzEuVatId(vatId);
    }

    if (shopCountry === countryEnum.RO) {
        return hasValidRoEuVatId(vatId);
    }

    return false;
}
