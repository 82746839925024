import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    ListItem,
    ListItemSecondaryAction,
    Select,
    TextField,
    Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import i18next from 'i18next';
import React from 'react';
import { ChangeEvent } from 'react';
import { Enum } from '../models/Enum';
import { ProductItem } from '../models/Order';
import { renderEnumOptions } from '../utils/enums';
import { calculatePriceWithoutTax, CurrencySymbol } from '../utils/price';
import { OversizedItemIndicator } from './OversizedItemIndicator';
import { ProductSubItems } from './ProductSubItems';
import { tooltipEnterDelay } from 'src/config';
import AddIcon from '@material-ui/icons/Add';

interface Props {
    index: number;
    country: string;
    currency: CurrencySymbol;
    item: ProductItem;
    disabled: boolean;
    validProductStatusEnum: Enum;
    className: string;
    subItemClassName: string;
    showDivider: boolean;
    oversizedProductCodes: string[] | undefined;
    onChange: (event: ChangeEvent<HTMLElement>) => void;
    onRemoveItem: (productItem: ProductItem) => void;
    onAddSubItem: (item: ProductItem) => void;
    onRemoveSubItem: (item: ProductItem, subItem: ProductItem) => void;
}

export const ProductItemView: React.SFC<Props> = ({
    index,
    country,
    currency,
    item,
    disabled,
    validProductStatusEnum,
    className,
    showDivider,
    oversizedProductCodes,
    subItemClassName,
    onChange,
    onRemoveItem,
    onAddSubItem,
    onRemoveSubItem
}) => (
    <>
        <ListItem className={className}>
            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2} md={1}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={`supplier${index}`}>
                                    {i18next.t('Supplier')}
                                </InputLabel>
                                <Input
                                    id={`supplier${index}`}
                                    value={item.supplier || ''}
                                    name={`items.${index}.supplier`}
                                    disabled={disabled}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} sm={4} md={2}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={`code${index}`}>
                                    {i18next.t('Code')}
                                </InputLabel>
                                <Input
                                    id={`code${index}`}
                                    value={item.productCode || ''}
                                    name={`items.${index}.productCode`}
                                    disabled={disabled}
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <OversizedItemIndicator
                                                isShown={oversizedProductCodes?.includes(
                                                    item?.productCode
                                                )}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={9}>
                            <TextField
                                label={i18next.t('Name')}
                                value={item.name || ''}
                                name={`items.${index}.name`}
                                disabled={disabled}
                                onChange={onChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={i18next.t('Variant')}
                                value={item.variant || ''}
                                name={`items.${index}.variant`}
                                disabled={disabled}
                                onChange={onChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={`weight${index}`}>
                                    {i18next.t('Weight')}
                                </InputLabel>
                                <Input
                                    id={`weight${index}`}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={item.weight || ''}
                                    name={`items.${index}.weight`}
                                    disabled={disabled}
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            kg
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={`volume${index}`}>
                                    {i18next.t('Volume')}
                                </InputLabel>
                                <Input
                                    id={`volume${index}`}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={item.volume || ''}
                                    name={`items.${index}.volume`}
                                    disabled={disabled}
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            m3
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label={i18next.t('Box count')}
                                type="number"
                                inputProps={{ min: 0 }}
                                value={item.boxCount || ''}
                                name={`items.${index}.boxCount`}
                                disabled={disabled}
                                fullWidth
                                onChange={onChange}
                            />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                label={i18next.t('Quantity')}
                                type="number"
                                inputProps={{ min: 0 }}
                                value={item.quantity || ''}
                                name={`items.${index}.quantity`}
                                disabled={disabled}
                                fullWidth
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={`unitPrice${index}`}>
                                    {i18next.t('Unit price')}
                                </InputLabel>
                                <Input
                                    id={`unitPrice${index}`}
                                    value={calculatePriceWithoutTax(
                                        item.unitPriceWithTax,
                                        country,
                                        item.vatRate
                                    )}
                                    disabled
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {currency}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel
                                    htmlFor={`unitPriceWithTax${index}`}
                                >
                                    {i18next.t('Unit price with tax')}
                                </InputLabel>
                                <Input
                                    id={`unitPriceWithTax${index}`}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={item.unitPriceWithTax}
                                    name={`items.${index}.unitPriceWithTax`}
                                    onChange={onChange}
                                    disabled={disabled}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {currency}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={`status${index}`}>
                                    {i18next.t('Status')}
                                </InputLabel>
                                <Select
                                    id={`status${index}`}
                                    value={item.status || ''}
                                    name={`items.${index}.status`}
                                    disabled={disabled}
                                    // @ts-ignore select
                                    onChange={onChange}
                                >
                                    {renderEnumOptions(validProductStatusEnum)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <FormControlLabel
                                key="inStockWhenOrdered"
                                control={
                                    <Checkbox
                                        checked={item.flags?.includes(
                                            'inStockWhenOrdered'
                                        )}
                                        disabled
                                        name="inStockWhenOrdered"
                                        color="primary"
                                    />
                                }
                                label={i18next.t('order.inStockWhenOrdered')}
                            />
                        </Grid>
                        <Grid container item justify="flex-end" xs={12} md={1}>
                            <Tooltip
                                title={i18next.t('Add sub item')}
                                enterDelay={tooltipEnterDelay}
                            >
                                <div>
                                    <IconButton
                                        color="default"
                                        onClick={() => onAddSubItem(item)}
                                        disabled={disabled}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <ListItemSecondaryAction>
                        <IconButton
                            type="button"
                            onClick={onRemoveItem.bind(null, item)}
                            disabled={disabled}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </Grid>
                <ProductSubItems
                    index={index}
                    parentItem={item}
                    validProductStatusEnum={validProductStatusEnum}
                    disabled={disabled}
                    subItems={item.subItems}
                    oversizedProductCodes={oversizedProductCodes}
                    className={subItemClassName}
                    onChange={onChange}
                    onRemoveSubItem={onRemoveSubItem}
                />
            </Grid>
        </ListItem>
        {showDivider && <Divider />}
    </>
);
