import { ProductItem } from 'src/models/Order';

const SERVICE_CODE = 'SLUZ';

export const isItemAnService = (productCode: string): boolean =>
    productCode.startsWith(SERVICE_CODE);

export const filterItemsWithoutServices = (
    items: ProductItem[]
): ProductItem[] =>
    items.filter(({ productCode }) => !isItemAnService(productCode));
