import i18next from 'i18next';
import { ProductItem, Customer, Shop, InvoiceType } from 'src/models/Order';

export interface OrderToShip {
    _id: string;
    orderNumber: string;
    createdDate: string;
    discount: string;
    discountType: string;
    shop: Shop;
    customer: {
        delivery: Customer;
        billing: { name: undefined; phone?: string };
    };
    invoice?: {
        id: string;
        numberFormatted: string;
        type: InvoiceType;
    };
    delivery: { type: string; unitPriceWithTax: undefined };
    billing: { type: string };
    items: ProductItem[];
    totalPriceWithTax?: number;
    isPaid: boolean;
    userNote?: string;
    shopNote?: string;
    appliedDiscount?: string;
    shippingNote?: string;
    expectedShippingDate?: string;
    withoutInvoice?: boolean;
    billNumber?: string;
}

export interface ShippingPlanningSharedData {
    query: OrdersToShipQuery;
    selectedOrdersIds: string[];
}

export interface OrdersToShipQuery {
    search?: string;
    shipping: Shipping;
}

export enum Shipping {
    COURIER_Z = 'COURIER',
    COURIER_H = 'COURIER_H',
    CARTAGE = 'CARTAGE',
    PERSONAL_PICKUP = 'PERSONAL_PICKUP'
}

const COURIER_SHIPPING_VALUES = [Shipping.COURIER_Z, Shipping.COURIER_H];

export const isCourierShipping = (shipping: string): boolean =>
    COURIER_SHIPPING_VALUES.includes(shipping as Shipping);

export function getSelectedOrders(
    checkAll: boolean,
    orders: OrderToShip[]
): string[] {
    if (!checkAll) {
        return [];
    }

    return orders.map(order => order._id);
}

export function getUpdateShippingSuccessText(shipping: Shipping): string {
    if (isCourierShipping(shipping)) {
        return i18next.t('shippingPlanning.assignOrdersToCourierSuccess');
    }

    return i18next.t('shippingPlanning.assignOrdersToCartageSuccess');
}

export function getUpdateShippingErrorText(shipping: Shipping): string {
    if (isCourierShipping(shipping)) {
        return i18next.t('shippingPlanning.assignOrdersToCourierError');
    }

    return i18next.t('shippingPlanning.assignOrdersToCartageError');
}

export function getOrderTotalWeight(items: ProductItem[]): number {
    return items.reduce(
        (orderWeight, item) => orderWeight + item.weight * item.quantity,
        0
    );
}
