import { handleActions } from 'redux-actions';
import { Enums } from '../../models/Enum';
import { receiveEnums, requestEnums } from './EnumsActions';
import { EnumsState } from './EnumsState';

const defaultState = {
    orderStatus: {
        enum: {
            data: []
        },
        isLoading: true
    },
    orderCallStatus: {
        enum: {
            data: []
        },
        isLoading: true
    },
    deliveryType: {
        enum: {
            data: []
        },
        isLoading: true
    },
    billingType: {
        enum: {
            data: []
        },
        isLoading: true
    },
    userRole: {
        enum: {
            data: []
        },
        isLoading: true
    },
    productStatus: {
        enum: {
            data: []
        },
        isLoading: true
    },
    shopType: {
        enum: {
            data: []
        },
        isLoading: true
    },
    country: {
        enum: {
            data: []
        },
        isLoading: true
    },
    discountType: {
        enum: {
            data: []
        },
        isLoading: true
    },
    productGeneralStatus: {
        enum: {
            data: []
        },
        isLoading: true
    },
    quatroFinancing: {
        enum: {
            data: []
        },
        isLoading: true
    }
};

type PayloadType = {
    enums: Enums;
};

const EnumsReducer = handleActions<EnumsState, PayloadType>(
    {
        [requestEnums.toString()]: state => ({
            ...state,
            orderStatus: {
                ...state.orderStatus,
                isLoading: true
            },
            orderCallStatus: {
                ...state.orderCallStatus,
                isLoading: true
            },
            deliveryType: {
                ...state.deliveryType,
                isLoading: true
            },
            billingType: {
                ...state.billingType,
                isLoading: true
            },
            userRole: {
                ...state.userRole,
                isLoading: true
            },
            productStatus: {
                ...state.productStatus,
                isLoading: true
            },
            shopType: {
                ...state.shopType,
                isLoading: true
            },
            country: {
                ...state.country,
                isLoading: true
            },
            discountType: {
                ...state.discountType,
                isLoading: true
            },
            productGeneralStatus: {
                ...state.productGeneralStatus,
                isLoading: true
            },
            quatroFinancing: {
                ...state.quatroFinancing,
                isLoading: true
            }
        }),
        [receiveEnums.toString()]: (state, action) => ({
            ...state,
            orderStatus: {
                enum: action.payload.enums.orderStatus,
                isLoading: false
            },
            orderCallStatus: {
                enum: action.payload.enums.orderCallStatus,
                isLoading: false
            },
            deliveryType: {
                enum: action.payload.enums.deliveryType,
                isLoading: false
            },
            billingType: {
                enum: action.payload.enums.billingType,
                isLoading: false
            },
            userRole: {
                enum: action.payload.enums.userRole,
                isLoading: false
            },
            productStatus: {
                enum: action.payload.enums.productStatus,
                isLoading: false
            },
            shopType: {
                enum: action.payload.enums.shopType,
                isLoading: false
            },
            country: {
                enum: action.payload.enums.country,
                isLoading: false
            },
            discountType: {
                enum: action.payload.enums.discountType,
                isLoading: false
            },
            productGeneralStatus: {
                enum: action.payload.enums.productGeneralStatus,
                isLoading: false
            },
            quatroFinancing: {
                enum: action.payload.enums.quatroFinancing,
                isLoading: false
            }
        })
    },
    defaultState
);

export { EnumsReducer };
