import React, { useCallback } from 'react';
import {
    TableRow,
    TableCell,
    Button,
    TableBody,
    Table
} from '@material-ui/core';
import i18next from 'i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Availability } from 'src/models/Availability';
import { useConfirmationDialog } from 'src/common/confirmationDialog/useConfirmationDialog';

interface Props {
    availability: Availability;
    onEditClick: (Availability: Availability) => void;
    onRemoveClick: (id: string) => void;
}

export const AvailabilityRow: React.FC<Props> = ({
    availability,
    onEditClick,
    onRemoveClick
}) => {
    const confirm = useConfirmationDialog();

    const handleEditAvailabilityClick = useCallback(() => {
        onEditClick(availability);
    }, [onEditClick, availability]);

    const handleRemoveAvailabilityClick = useCallback(async () => {
        const isConfirmed = await confirm(
            i18next.t('availabilityList.removeAvailabilityConfirmation')
        );
        if (!isConfirmed) {
            return;
        }

        onRemoveClick(availability._id);
    }, [onRemoveClick, confirm, availability._id]);

    return (
        <>
            <TableRow>
                <TableCell>{availability.name}</TableCell>
                <TableCell>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>SK</TableCell>
                                <TableCell>
                                    {availability.inStockTime}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>HU</TableCell>
                                <TableCell>
                                    {availability.inStockTimeHU}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>CZ</TableCell>
                                <TableCell>
                                    {availability.inStockTimeCZ}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>RO</TableCell>
                                <TableCell>
                                    {availability.inStockTimeRO}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
                <TableCell>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>SK</TableCell>
                                <TableCell>
                                    {availability.outOfStockTime}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>HU</TableCell>
                                <TableCell>
                                    {availability.outOfStockTimeHU}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>CZ</TableCell>
                                <TableCell>
                                    {availability.outOfStockTimeCZ}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>RO</TableCell>
                                <TableCell>
                                    {availability.outOfStockTimeRO}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        color="primary"
                        onClick={handleEditAvailabilityClick}
                    >
                        <EditIcon />
                    </Button>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={handleRemoveAvailabilityClick}
                    >
                        <DeleteIcon />
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
};
