module.exports = {
    orderStatus: {
        RECEIVED: 'RECEIVED',
        TO_ORDER: 'TO_ORDER',
        ORDERED: 'ORDERED',
        PROCESSED: 'PROCESSED',
        READY_TO_SHIP: 'READY_TO_SHIP',
        DONE: 'DONE',
        CANCELED: 'CANCELED'
    },
    orderCallStatus: {
        NOT_CALLED: 'NOT_CALLED',
        AFTER_ORDERED: 'A_ORDER',
        BEFORE_DELIVERY: 'B_DELIVERY',
        AFTER_DELIVERY: 'A_DELIVERY',
        NOT_DELIVERED: 'NOT_DELIVERED'
    },
    deliveryType: {
        COURIER_BIG: 'COURIER_BIG',
        COURIER_SMALL: 'COURIER_SMALL',
        EVENING: 'EVENING',
        PERSONAL: 'PERSONAL',
        POST: 'POST',
        FREE: 'FREE',
        EXPRESS: 'EXPRESS'
    },
    billingType: {
        BANK_TRANSFER: 'BANK_TRANSFER',
        CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
        GOPAY: 'GOPAY',
        CASH_ON_PLACE: 'CASH_ON_PLACE',
        QUATRO: 'QUATRO'
    },
    userRole: {
        ADMIN: 'ADMIN',
        ACCOUNTANT: 'ACCOUNTANT',
        USER: 'USER',
        SIMPLE_USER: 'SIMPLE_USER',
        DRIVER: 'DRIVER'
    },
    productStatus: {
        NONE: 'NONE',
        STOCKED: 'STOCKED',
        READY_TO_SHIP: 'READY_TO_SHIP',
        NOT_STOCKED: 'NOT_STOCKED',
        ORDERED: 'ORDERED',
        DONE: 'DONE',
        RETURNED: 'RETURNED'
    },
    shopType: {
        WEBNODE: 'WEBNODE',
        SHOPTET: 'SHOPTET'
    },
    country: {
        SK: 'SK',
        HU: 'HU',
        CZ: 'CZ',
        RO: 'RO'
    },
    discountType: {
        PERCENTAGE: 'PERCENTAGE',
        NOMINAL: 'NOMINAL'
    },
    productGeneralStatus: {
        IN_SALE: 'IN_SALE',
        NOT_IN_SALE: 'NOT_IN_SALE'
    }
};
