import {
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Select
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import { ChangeEvent, Component, ReactNode } from 'react';
import React from 'react';
import { Enum } from '../models/Enum';
import { Item } from '../models/Order';
import { renderEnumOptions } from '../utils/enums';
import { calculatePriceWithoutTax, CurrencySymbol } from '../utils/price';

interface Props {
    item: Item;
    namePrefix: string;
    typeEnum: Enum;
    country: string;
    currency: CurrencySymbol;
    disabled: boolean;
    disabledEnumCodes?: string[];
    additionalFields?: ReactNode;
    onChange: (event: ChangeEvent<HTMLElement>) => void;
}

const decorate = withStyles(() => {
    const styles: StyleRules = {
        formControl: {
            width: '100%'
        }
    };
    return styles;
});

type PropsWithStyles = Props & WithStyles<'formControl'>;

class ItemViewBase extends Component<PropsWithStyles> {
    render() {
        const {
            item,
            namePrefix,
            country,
            currency,
            disabled,
            disabledEnumCodes,
            onChange,
            classes
        } = this.props;

        return (
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="type">
                            {i18next.t('Type')}
                        </InputLabel>
                        <Select
                            id="type"
                            value={item.type || ''}
                            name={`${namePrefix}.type`}
                            disabled={disabled}
                            // @ts-ignore select
                            onChange={onChange}
                            fullWidth
                        >
                            {renderEnumOptions(
                                this.props.typeEnum,
                                disabledEnumCodes
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor={`unitPrice${namePrefix}`}>
                            {i18next.t('Price')}
                        </InputLabel>
                        <Input
                            id={`unitPrice${namePrefix}`}
                            value={calculatePriceWithoutTax(
                                item.unitPriceWithTax,
                                country,
                                item.vatRate
                            )}
                            disabled
                            startAdornment={
                                <InputAdornment position="start">
                                    {currency}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor={`unitPriceWithTax${namePrefix}`}>
                            {i18next.t('Price with tax')}
                        </InputLabel>
                        <Input
                            id={`unitPriceWithTax${namePrefix}`}
                            type="number"
                            inputProps={{ min: 0 }}
                            value={item.unitPriceWithTax}
                            name={`${namePrefix}.unitPriceWithTax`}
                            disabled={disabled}
                            onChange={onChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    {currency}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                {this.props.additionalFields}
            </Grid>
        );
    }
}

const ItemView = decorate(ItemViewBase);

export { ItemView };
