import React, { useEffect, useState } from 'react';

import {
    Button,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper
} from '@material-ui/core';
import i18next from 'i18next';
import { AvailabilityDialog } from './AvailabilityDialog';
import { Availability } from 'src/models/Availability';
import { AvailabilityRows } from './AvailabilityRows';
import { useRequest } from 'src/common/useRequestHook';
import { RequestMethod } from 'src/utils/request';
import { API_URLS } from 'src/common/ApiService';
import { useDispatch } from 'react-redux';
import { showNotification } from 'src/common/notifications/NotificationsActions';
import { NotificationVariant } from 'src/models/Notification';

const defaultAvailability: Availability = {
    name: '',
    inStockTime: 0,
    outOfStockTime: 0,
    inStockTimeHU: 0,
    outOfStockTimeHU: 0,
    inStockTimeCZ: 0,
    outOfStockTimeCZ: 0,
    inStockTimeRO: 0,
    outOfStockTimeRO: 0
};

const useStyles = makeStyles(theme => ({
    addButtonPosition: {
        margin: theme.spacing(1),
        textAlign: 'right'
    }
}));

export const ProductsAvailability: React.FC = () => {
    const dispatch = useDispatch();
    const [
        fetchAvailabilities,
        { inProgress: isLoading, response: availabilities = [] }
    ] = useRequest<Availability[]>(
        RequestMethod.GET,
        API_URLS.productAvailabilities
    );

    const [refreshProductsInfo, { inProgress: isUpdating }] = useRequest(
        RequestMethod.POST,
        API_URLS.refreshProductsInfo
    );

    const [createAvailability] = useRequest<Availability[]>(RequestMethod.POST);
    const [updateAvailability] = useRequest<Availability[]>(
        RequestMethod.PATCH
    );
    const [deleteAvailability] = useRequest<Availability[]>(
        RequestMethod.DELETE
    );

    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [availability, setAvailability] = useState({
        ...defaultAvailability
    });

    useEffect(() => {
        fetchAvailabilities();
    }, [fetchAvailabilities]);

    const addNewAvailability = () => {
        setAvailability({ ...defaultAvailability });
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const submitAvailability = async () => {
        if (
            Object.values(availability).some(
                value => value === null || value === undefined || value === ''
            )
        ) {
            dispatch(
                showNotification({
                    id: `missing-availability-info_${Date.now()}`,
                    variant: NotificationVariant.Error,
                    text: i18next.t('Products info missing save error')
                })
            );
            return;
        }

        try {
            if (availability._id) {
                await updateAvailability(
                    availability,
                    `${API_URLS.productAvailabilities}/${availability._id}`
                );
                return;
            }
            await createAvailability(
                availability,
                API_URLS.productAvailabilities
            );
        } finally {
            await fetchAvailabilities();
            setIsDialogOpen(false);
        }
    };

    const removeAvailability = async (id: string) => {
        await deleteAvailability(
            undefined,
            `${API_URLS.productAvailabilities}/${id}`
        );
        fetchAvailabilities();
    };

    const editAvailability = (availability: Availability) => {
        setAvailability(availability);
        setIsDialogOpen(true);
    };

    const onAvailabilityChange = (name: string, value: string) => {
        setAvailability({ ...availability, [name]: value });
    };

    const onProductsInfoRefresh = () => {
        refreshProductsInfo();
    };

    return (
        <>
            <h2>{i18next.t('Products info')}</h2>
            <p>{i18next.t('Products info description')}</p>
            <div className={classes.addButtonPosition}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onProductsInfoRefresh}
                    disabled={isUpdating}
                >
                    {i18next.t('refreshProductsInfo')}
                </Button>
            </div>
            <h2>{i18next.t('Availability settings')}</h2>
            <TableContainer component={Paper}>
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18next.t('Availability')}</TableCell>
                            <TableCell>{i18next.t('Storage time')}</TableCell>
                            <TableCell>{i18next.t('Delivery time')}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <AvailabilityRows
                            isLoading={isLoading}
                            availabilities={availabilities}
                            onEditClick={editAvailability}
                            onRemoveClick={removeAvailability}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.addButtonPosition}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addNewAvailability}
                >
                    {i18next.t('Add availability')}
                </Button>
            </div>

            <AvailabilityDialog
                open={isDialogOpen}
                availability={availability}
                onCancel={closeDialog}
                onSubmit={submitAvailability}
                onAvailabilityChange={onAvailabilityChange}
            />
        </>
    );
};
