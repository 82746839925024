import {
    Checkbox,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    List,
    Select,
    Tooltip
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import yellow from '@material-ui/core/colors/yellow';
import Grid from '@material-ui/core/Grid';
import { InputProps } from '@material-ui/core/Input';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import MUIRichTextEditor from 'mui-rte';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18next from 'i18next';
import { Component } from 'react';
import React from 'react';
import { discountType, orderCallStatus } from '../common/enums/enums';
import { EnumsState } from '../common/enums/EnumsState';
import { tooltipEnterDelay } from '../config';
import { Enum } from '../models/Enum';
import { Order, ProductItem } from '../models/Order';
import { renderEnumOptions, resolveEnumValue } from '../utils/enums';
import { formatDateTime, formatUtcDate } from '../utils/formatters';
import { CurrencySymbol, hasZeroTax } from '../utils/price';
import { CustomerView } from './CustomerView';
import { ItemView } from './ItemView';
import {
    calculateTotalPrice,
    isLastArrayItem,
    isOrderCanceledOrDone,
    getShopNoteRawState,
    calculateTotalOrderWeight
} from './OrderService';
import { ProductItemView } from './ProductItemView';
import { TMUIRichTextEditorRef } from 'mui-rte/src/MUIRichTextEditor';
import { FlagsView } from './FlagsView';
import { IsPaidCheckbox } from './IsPaidCheckbox';
import { BillingItem } from './BillingItem';
import { WithoutInvoiceCheckbox } from './WithoutInvoiceCheckbox';

const styles = theme =>
    createStyles({
        productItem: {
            '&:nth-of-type(even)': {
                backgroundColor: grey[50]
            },
            '&:nth-of-type(odd)': {
                backgroundColor: grey[200]
            },
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        },
        subProductItem: {
            '&:nth-of-type(even)': {
                backgroundColor: yellow[50]
            },
            '&:nth-of-type(odd)': {
                backgroundColor: yellow[100]
            },
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        shopNote: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        }
    });

interface Props extends WithStyles<typeof styles> {
    enums: EnumsState;
    isEditing: boolean;
    validProductStatusEnum: Enum;
    order?: Order;
    currency: CurrencySymbol;
    oversizedProductCodes: string[] | undefined;
    isSimpleUser: boolean;
    onAddItem: () => void;
    onAddSubItem: (item: ProductItem) => void;
    onRemoveSubItem: (item: ProductItem, subItem: ProductItem) => void;
    onRemoveItem: (productItem: ProductItem) => void;
    onOrderChange: (propertyName: string, value: string | number) => void;
}

class OrderDetailsViewBase extends Component<Props> {
    ref = React.createRef<TMUIRichTextEditorRef>();

    renderMainInfo() {
        const {
            order,
            currency,
            enums,
            isEditing,
            isSimpleUser,
            onOrderChange
        } = this.props;

        const withoutVat = hasZeroTax(
            order.shop.country,
            order.customer.billing.vatId
        );
        const totalPriceWithTax = calculateTotalPrice(order, withoutVat);
        const totalPriceWithoutTax = calculateTotalPrice(order, true);
        const totalOrderWeight = calculateTotalOrderWeight(order.items);

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FlagsView
                        disabled={isEditing}
                        isSimpleUser={isSimpleUser}
                        order={order}
                        onOrderChange={onOrderChange}
                        showZeroTaxFlag={withoutVat}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label={i18next.t('Shop')}
                        value={order.shop.name}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label={i18next.t('Created date')}
                        value={formatDateTime(order.createdDate)}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label={i18next.t('Expected shipping date')}
                        value={
                            order.expectedShippingDate
                                ? formatUtcDate(order.expectedShippingDate)
                                : '-'
                        }
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="totalWeight">
                            {i18next.t('Total weight')}
                        </InputLabel>
                        <Input
                            id="totalWeight"
                            type="number"
                            value={totalOrderWeight}
                            name="totalWeight"
                            disabled
                            endAdornment={
                                <InputAdornment position="start">
                                    kg
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label={i18next.t('Status')}
                        value={resolveEnumValue(
                            enums.orderStatus.enum,
                            order.status
                        )}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <TextField
                        label={i18next.t('Applied discount')}
                        value={order.appliedDiscount || i18next.t('None')}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="discountType">
                            {i18next.t('Discount type')}
                        </InputLabel>
                        <Select
                            id="discountType"
                            name="discountType"
                            value={order.discountType}
                            onChange={this.onChange}
                            fullWidth
                            disabled={isSimpleUser}
                        >
                            {renderEnumOptions(enums.discountType.enum)}
                        </Select>
                    </FormControl>
                </Grid>
                {this.renderDiscount()}
                <Grid item md={3} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="callStatus">
                            {i18next.t('Call status')}
                        </InputLabel>
                        <Select
                            id="callStatus"
                            name="callStatus"
                            value={
                                order.callStatus || orderCallStatus.NOT_CALLED
                            }
                            onChange={this.onChange}
                            fullWidth
                        >
                            {renderEnumOptions(enums.orderCallStatus.enum)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="totalPrice">
                            {i18next.t('Total price')}
                        </InputLabel>
                        <Input
                            id="totalPrice"
                            type="number"
                            value={totalPriceWithoutTax.toFixed(2)}
                            name="totalPrice"
                            disabled
                            startAdornment={
                                <InputAdornment position="start">
                                    {currency}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="totalPriceWithTax">
                            {i18next.t('Total price with tax')}
                        </InputLabel>
                        <Input
                            id="totalPriceWithTax"
                            type="number"
                            value={totalPriceWithTax}
                            name="totalPriceWithTax"
                            disabled
                            startAdornment={
                                <InputAdornment position="start">
                                    {currency}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="billNumber">
                            {i18next.t('Bill number')}
                        </InputLabel>
                        <Input
                            id="billNumber"
                            type="string"
                            value={order.billNumber}
                            onChange={this.onChange}
                            name="billNumber"
                            disabled={isSimpleUser}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={4}>
                    <FormControlLabel
                        key="isIgnoredFromStats"
                        control={
                            <Checkbox
                                onChange={this.onCheckboxChange}
                                checked={order.isIgnoredFromStats}
                                disabled={isSimpleUser}
                                name="isIgnoredFromStats"
                                color="primary"
                            />
                        }
                        label={i18next.t('order.isIgnoredFromStats')}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <WithoutInvoiceCheckbox
                        order={order}
                        onChange={this.onCheckboxChange}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <IsPaidCheckbox
                        order={order}
                        onChange={this.onCheckboxChange}
                    />
                </Grid>
            </Grid>
        );
    }

    renderDiscount() {
        const { order, currency, isSimpleUser } = this.props;
        const discountProps: InputProps = {};

        if (order.discountType === discountType.PERCENTAGE) {
            discountProps.endAdornment = (
                <InputAdornment position="start">%</InputAdornment>
            );
        }

        if (order.discountType === discountType.NOMINAL) {
            discountProps.startAdornment = (
                <InputAdornment position="start">{currency}</InputAdornment>
            );
        }

        return (
            <Grid item md={3} xs={6}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="discount">
                        {i18next.t('Discount')}
                    </InputLabel>
                    <Input
                        id="discount"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={order.discount || ' '}
                        name="discount"
                        onChange={this.onChange}
                        disabled={isSimpleUser}
                        {...discountProps}
                    />
                </FormControl>
            </Grid>
        );
    }

    renderNotes() {
        const { order, classes, isSimpleUser } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <TextField
                        label={i18next.t('User note')}
                        value={order.userNote || ''}
                        multiline
                        name="userNote"
                        onChange={this.onChange}
                        fullWidth
                        disabled={isSimpleUser}
                    />
                    <TextField
                        label={i18next.t('order.shippingNote')}
                        value={order.shippingNote || ''}
                        name="shippingNote"
                        onChange={this.onChange}
                        fullWidth
                        disabled={isSimpleUser}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth className={classes.shopNote}>
                        <InputLabel htmlFor="shopNote">
                            {i18next.t('Shop note')}
                        </InputLabel>
                        <MUIRichTextEditor
                            id="shopNote"
                            ref={this.ref}
                            label={i18next.t('Start typing here')}
                            defaultValue={getShopNoteRawState(order.shopNote)}
                            controls={[
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                                'highlight',
                                'undo',
                                'redo',
                                'numberList',
                                'bulletList'
                            ]}
                            onSave={this.onShopNoteChange}
                            onBlur={this.onShopNoteBlur}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    renderAddresses() {
        const { order, enums, isSimpleUser } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            {i18next.t('Billing address')}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <CustomerView
                                customer={order.customer.billing}
                                namePrefix="customer.billing"
                                countryEnum={enums.country.enum}
                                onChange={this.onChange}
                                disabled={isSimpleUser}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                <Grid item md={6} xs={12}>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            {i18next.t('Shipping address')}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <CustomerView
                                customer={order.customer.delivery}
                                namePrefix="customer.delivery"
                                countryEnum={enums.country.enum}
                                onChange={this.onChange}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        );
    }

    renderProductItems() {
        const { order, onAddItem, isSimpleUser } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            {i18next.t('Items')}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {this.renderItemList()}
                                </Grid>
                                <Grid container item justify="flex-end" xs={12}>
                                    <Tooltip
                                        title={i18next.t('Add item')}
                                        enterDelay={tooltipEnterDelay}
                                    >
                                        <div>
                                            <IconButton
                                                color="default"
                                                onClick={onAddItem}
                                                disabled={
                                                    isSimpleUser ||
                                                    isOrderCanceledOrDone(order)
                                                }
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        );
    }

    renderPaymentAndShipping() {
        const { order, currency, isSimpleUser } = this.props;
        const disabled = isSimpleUser || isOrderCanceledOrDone(order);
        return (
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            {i18next.t('Payment method')}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <BillingItem
                                order={order}
                                currency={currency}
                                onChange={this.onChange}
                                disabled={disabled}
                                onCheckboxChange={this.onCheckboxChange}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                <Grid item md={6} xs={12}>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            {i18next.t('Shipping')}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ItemView
                                country={order.shop.country}
                                currency={currency}
                                item={order.delivery}
                                namePrefix="delivery"
                                onChange={this.onChange}
                                disabled={disabled}
                                typeEnum={this.props.enums.deliveryType.enum}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        );
    }

    renderItemList() {
        const { order } = this.props;
        return (
            <List dense disablePadding>
                {order.items.map(this.renderItem)}
            </List>
        );
    }

    renderItem = (item: ProductItem, index: number, array: ProductItem[]) => {
        const {
            validProductStatusEnum,
            order,
            currency,
            classes,
            oversizedProductCodes,
            isSimpleUser,
            onRemoveItem,
            onAddSubItem,
            onRemoveSubItem
        } = this.props;
        const disabled = isOrderCanceledOrDone(order);
        return (
            <ProductItemView
                key={index}
                country={order.shop.country}
                currency={currency}
                item={item}
                index={index}
                className={classes.productItem}
                subItemClassName={classes.subProductItem}
                disabled={isSimpleUser || disabled}
                validProductStatusEnum={validProductStatusEnum}
                oversizedProductCodes={oversizedProductCodes}
                showDivider={!isLastArrayItem(index, array.length)}
                onRemoveItem={onRemoveItem}
                onChange={this.onChange}
                onAddSubItem={onAddSubItem}
                onRemoveSubItem={onRemoveSubItem}
            />
        );
    };

    render() {
        return (
            <form style={{ paddingTop: 20 }}>
                {this.renderMainInfo()}
                {this.renderNotes()}
                {this.renderAddresses()}
                {this.renderProductItems()}
                {this.renderPaymentAndShipping()}
            </form>
        );
    }

    onChange = event => {
        this.props.onOrderChange(event.target.name, event.target.value);
    };

    onShopNoteChange = (data: string) => {
        this.props.onOrderChange('shopNote', data);
    };

    onShopNoteBlur = () => {
        this.ref.current?.save();
    };

    onCheckboxChange = (event, isChecked) => {
        this.props.onOrderChange(event.target.name, isChecked);
    };
}

const OrderDetailsView = withStyles(styles)(OrderDetailsViewBase);

export { OrderDetailsView };
