import { BulkGateMessageType, Cartage, Message } from 'src/models/Cartage';
import { Customer, ProductItem } from 'src/models/Order';
import {
    OrderToShip,
    getOrderTotalWeight
} from 'src/shippingPlanning/ShippingPlanningService';
import { Driver } from './useDriverUsers';
import { filterItemsWithoutServices } from 'src/order/OrderServiceItemService';

export interface CartageSharedData {
    isEditing?: boolean;
    cartage?: Cartage;
    fetchCartageOrders?(): void;
}

export function getCartageTotalWeight(orders: OrderToShip[] = []): number {
    return orders.reduce(
        (cartageWeight, order) =>
            cartageWeight + getOrderTotalWeight(order.items),
        0
    );
}

export function getOrderBoxCount(order: OrderToShip): string {
    const count = filterItemsWithoutServices(order.items).reduce(
        (sum, { boxCount, quantity }) => sum + quantity * boxCount,
        0
    );

    if (Number.isNaN(count)) {
        return '-';
    }

    return count.toString();
}

export function getReadyToShipMessage(
    messages: Message[] = []
): Message | undefined {
    return messages.find(
        ({ type }) => type === BulkGateMessageType.CARTAGE_READY_TO_SHIP
    );
}

function getAddressLinkFormat({ street, city, zip }: Customer): string {
    const zipAndCity = `${zip} ${city}`;
    return `${encodeURIComponent(street)},${encodeURIComponent(zipAndCity)}`;
}

const BASE_MAP_LINK = 'https://www.google.sk/maps/place/';
export function getAddressGoogleLink(delivery: Customer): string {
    return `${BASE_MAP_LINK}${getAddressLinkFormat(delivery)}`;
}

const BASE_DIRECTION_MAP_LINK = 'https://www.google.sk/maps/dir/';

export function getCartageDirectionGoogleLink(
    orders: OrderToShip[],
    driverAddress: string
): string {
    const addresses = orders.map(({ customer }) =>
        getAddressLinkFormat(customer.delivery)
    );

    const startingPoint = encodeURIComponent(driverAddress);

    return `${BASE_DIRECTION_MAP_LINK}${startingPoint}/${addresses.join('/')}`;
}

export function getDriverAddress(
    drivers: Driver[],
    driver?: string
): string | undefined {
    const driverUser = drivers.find(({ username }) => driver === username);

    return driverUser?.address;
}

export function containsOrderOversizedItem(
    items: ProductItem[],
    oversizedProductCodes: string[] = []
): boolean {
    return items.some(({ productCode }) =>
        oversizedProductCodes.includes(productCode)
    );
}
