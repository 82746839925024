import React from 'react';
import { useSession } from 'src/common/session/useSession';
import { Order } from 'src/models/Order';
import { ItemView } from './ItemView';
import { CurrencySymbol } from 'src/utils/price';
import { useEnums } from 'src/common/enums/useEnumsHook';
import { getDisabledBillingCodes } from './OrderAccountRightsService';
import { isAccountant } from 'src/common/session/SessionService';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Select
} from '@material-ui/core';
import i18next from 'i18next';
import { renderEnumOptions } from 'src/utils/enums';
import { billingType } from 'src/common/enums/enums';

interface Props {
    order: Order;
    currency: CurrencySymbol;
    disabled: boolean;
    onChange(evt): void;
    onCheckboxChange(event, isChecked: boolean): void;
}

export const BillingItem: React.FC<Props> = ({
    order,
    currency,
    disabled,
    onCheckboxChange,
    onChange
}) => {
    const session = useSession();
    const enums = useEnums();

    return (
        <ItemView
            country={order.shop.country}
            currency={currency}
            item={order.billing}
            namePrefix="billing"
            onChange={onChange}
            disabled={disabled}
            disabledEnumCodes={getDisabledBillingCodes(
                order,
                isAccountant(session)
            )}
            typeEnum={enums.billingType.enum}
            additionalFields={
                order.billing.type === billingType.QUATRO && (
                    <>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="quatro.financing">
                                    {i18next.t('quatro.financing')}
                                </InputLabel>
                                <Select
                                    id="quatro.financing"
                                    value={order.quatro?.financing || ''}
                                    name="quatro.financing"
                                    // @ts-ignore select
                                    onChange={onChange}
                                    fullWidth
                                >
                                    {renderEnumOptions(
                                        enums.quatroFinancing.enum
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <FormControlLabel
                                key="quatro.isConfirmed"
                                control={
                                    <Checkbox
                                        onChange={onCheckboxChange}
                                        checked={order?.quatro?.isConfirmed}
                                        name="quatro.isConfirmed"
                                        color="secondary"
                                        disabled={disabled}
                                    />
                                }
                                label={i18next.t('quatro.isConfirmed')}
                            />
                        </Grid>
                    </>
                )
            }
        />
    );
};
